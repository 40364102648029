// Utilities for reading common meta tag values.
import { readMetaTag } from '@rsos/base-utils/metaTags';

export { readMetaTag };
export {
  getAPIHost,
  getSessionAge,
  getMCOEnvironment,
  getRECProductName,
  getGoogleMapsApiKey,
  API_HOST_META_TAG_NAME,
  PRODUCTION_API_HOST,
  SESSION_AGE,
  MCO_ENV,
  REC_PRODUCT_NAME,
  GOOGLE_MAPS_KEY
} from '@rsos/base-utils/metaTags';

export const SANDBOX_API_HOST = 'https://api-sandbox.rapidsos.com';

export const APP_NAME = 'fe-app-name';
export const CALL_QUEUE_MAX_AGE = 'mco-call-queue-max-age';
export const EXPIRED_CALL_AGE = 'mco-expired-call-age';
export const CALL_QUEUE_MAX_SIZE = 'mco-call-queue-max-size';
export const CSP_ALERT_QUEUE_MAX_SIZE = 'mco-csp-alert-queue-max-size';
export const RSP_ALERT_QUEUE_MAX_SIZE = 'mco-rsp-alert-queue-max-size';
export const CDN_HOST = 'cdn-host';
export const FORMSITE_FORM_NAME = 'formsite-form-key';
export const MAP_PROVIDER = 'map-provider';
export const MIDAS_TEST_API_HOST = 'midas-test-api-host';
export const RECAPTCHA_META_TAG_NAME = 'mco-recaptcha-key';
export const SENTRY_DSN = 'sentry-dsn';
export const SESSION_NOTIFY_AGE = 'session-notify-age';
export const TALKJS_SERVER = 'talkjs-server';
export const TALKJS_APP_ID = 'talkjs-app-id';
export const TYPEFORM_SIGNUP_ENDPOINT = 'typeform-signup-endpoint';
export const AGENT_511_WEIGHT = 'agent-511-width';
export const AGENT_511_HEIGHT = 'agent-511-height';
export const GEOCOMM_VLS_KEY = 'geocomm-vls-key';
export const IAR_RESPONDERS_LINK = 'iar-responders-link';
export const CONVEY_SDK_BUNDLE_LINK = 'convey-sdk-bundle-link';
export const CONVEY_SDK_STYLE_LINK = 'convey-sdk-style-link';

/**
 * Returns the API host corresponding to the environment's stack.
 */
export const getCDNHost = () => readMetaTag(CDN_HOST);

/**
 * Returns the maximum age for a call to be held in the emergency_data call
 * queue.
 */
export const getCallQueueMaxAge = () =>
  readMetaTag(CALL_QUEUE_MAX_AGE) || 1800000;

/**
 * Returns the expired call asg for a call to be held in the emergency_data call
 * queue.
 */
export const getExpiredCallAge = () => readMetaTag(EXPIRED_CALL_AGE) || 7200;

/**
 * Returns the maximum size to be used by the call queue in emergency_data.
 */
export const getCallQueueMaxSize = () => readMetaTag(CALL_QUEUE_MAX_SIZE) || 50;

/**
 * Returns the maximum size to be used by the alert queue in emergency_data.
 */
export const getRspAlertQueueMaxSize = () =>
  readMetaTag(RSP_ALERT_QUEUE_MAX_SIZE) || 150;

/**
 * Returns the maximum size to be used by the call queue in emergency_data.
 */
export const getCspAlertQueueMaxSize = () =>
  readMetaTag(CSP_ALERT_QUEUE_MAX_SIZE) || 400;

/**
 * Returns the key for the recaptcha typically used alongside the login form.
 */
export const getRecaptchaSiteKey = () =>
  readMetaTag(RECAPTCHA_META_TAG_NAME) || '';

/**
 * Returns the Sentry DSN corresponding to the stack.
 */
export const getSentryDSN = () => readMetaTag(SENTRY_DSN) || '';

/**
 * Returns the environment corresponding to the current stack.
 */
export const getTypeformSignupEndpoint = () =>
  readMetaTag(TYPEFORM_SIGNUP_ENDPOINT) || '';

/**
 * Returns the name of the current frontend application.
 */
export const getAppName = () => readMetaTag(APP_NAME) || '';

/**
 * Returns the key for formsite form to initialize formsite integration
 */
export const getFormsiteFormKey = () => readMetaTag(FORMSITE_FORM_NAME) || '';

/**
 * Returns the Midas Test API host corresponding to the environment's stack.
 * Production uses sandbox
 */
export const getMidasTestAPIHost = () =>
  readMetaTag(MIDAS_TEST_API_HOST) || SANDBOX_API_HOST;

/**
 * Returns the count down timer for showing
 * popup modal to notify user will be logged out
 */
export const getSessionNotifyAge = () =>
  parseInt(readMetaTag(SESSION_NOTIFY_AGE)) || parseInt(1800);

/**
 * Returns TalkJS api server
 */
export const getTalkJSServer = () => readMetaTag(TALKJS_SERVER);

/**
 * Returns TalkJS app ID
 */
export const getTalkJSAppId = () => readMetaTag(TALKJS_APP_ID);

/**
 * Returns Esri Maps API key
 */
export const getEsriApiKey = () => readMetaTag('esri-maps-key');

/**
 * Returns Agent511 window popup width and height
 */
export const getAgent511Width = () => readMetaTag(AGENT_511_WEIGHT) || 600;
export const getAgent511Height = () => readMetaTag(AGENT_511_HEIGHT) || 649;

/**
 * Return Geocomm VLS API key
 */
export const getGeocommVLSApiKey = () => readMetaTag(GEOCOMM_VLS_KEY);

/**
 * Return IaR responders link
 */
export const getIaRrespondersLink = () => readMetaTag(IAR_RESPONDERS_LINK);

/**
 * Returns the product name corresponding to the current environment.
 */
export const getProductName = () => readMetaTag('product-name');

export const getMediaHost = () => readMetaTag('media-api-host');

/**
 * Return Convey SDK bundle link
 */
 export const getConveySDKBundleLink = () => readMetaTag(CONVEY_SDK_BUNDLE_LINK);

/**
  * Return Convey SDK style link
  */
export const getConveySDKStyleLink = () => readMetaTag(CONVEY_SDK_STYLE_LINK);

