import { ToastContainer } from 'react-toastify';
import styled, { css } from 'styled-components';
import { BP_LAPTOP } from '@rsos/constants/breakpoints';

const sharedToastStyle = css`
  display: flex;
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
  height: 48px;
  button[aria-label='close'] {
    display: flex;
    align-items: center;
    height: 100%;
    opacity: 1;
    align-self: center;
  }

`;

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__overwrite-body {
    margin: 0 8px 0 0;
    font-size: 14px;
    padding: 0px;
    overflow: hidden;
    /* react toastify wraps the content in a div with no class on it. when content is long, by default it wraps */
    > div {
      overflow: auto; /* this ensures that there is ellipsis when the content is too long */
      width: 100%;
    }
  }
  &&.Toastify__toast-container {
    width: 650px;
    @media (max-width: ${BP_LAPTOP}px) {
      width: 480px;
    }
  }
  .Toastify__toast {
    min-height: 35px;
  }
  .Toastify__overwrite-success {
    ${sharedToastStyle}
    background-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.background};
    button[aria-label='close'] {
      color: ${({ theme }) => theme.colors.background};
    }
  }
  .Toastify__overwrite-error {
    ${sharedToastStyle}
    background-color: ${({ theme }) => theme.colors.lightDanger};
    color: ${({ theme }) => theme.colors.background};
    button[aria-label='close'] {
      color: ${({ theme }) => theme.colors.background};
    }
    a {
      color: ${({ theme }) => theme.colors.background};
      text-decoration-color: ${({ theme }) => theme.colors.background};
    }
  }
  .Toastify__overwrite-info {
    ${sharedToastStyle}
    background-color: ${({ theme }) => theme.colors.primaryBase};
    color: ${({ theme }) => theme.colors.background};
    button[aria-label='close'] {
      color: ${({ theme }) => theme.colors.background};
    }
  }
  .Toastify__overwrite-default {
    ${sharedToastStyle}
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.primaryText};
    button[aria-label='close'] {
      color: ${({ theme }) => theme.colors.secondaryText};
    }
  }
  .Toastify__agency-share-chat-toast-shared {
    ${sharedToastStyle}
    background-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.background};
    button[aria-label='close'] {
      color: ${({ theme }) => theme.colors.background};
    }
  }
  .Toastify__agency-share-chat-toast-received {
    ${sharedToastStyle}
    background-color: ${({ theme }) =>
      theme.colors.agencyShareChat.toast.bgReceived};
    color: ${({ theme }) => theme.colors.background};
    button[aria-label='close'] {
      color: ${({ theme }) => theme.colors.background};
    }
  }
  .Toastify__agency-share-chat-toast-unread {
    ${sharedToastStyle}
    background-color: ${({ theme }) => theme.colors.primaryBase};
    color: ${({ theme }) => theme.colors.background};
    button[aria-label='close'] {
      color: ${({ theme }) => theme.colors.background};
    }
  }

  .Toastify__active-assailant {
    ${sharedToastStyle}
    background-color: ${({ theme }) => theme.colors.lightDanger};
    color: ${({ theme }) => theme.colors.background};
    button[aria-label='close'] {
      color: ${({ theme }) => theme.colors.background};
    }
  }
  .Toastify__train-derailment {
    ${sharedToastStyle}
    background-color: ${({ theme }) => theme.colors.trainIncidentAlert.iconBg};
    color: ${({ theme }) => theme.colors.trainIncidentAlert.iconColor};
    button[aria-label='close'] {
      color: ${({ theme }) => theme.colors.trainIncidentAlert.iconColor};
    }
  }
  .Toastify__alert-default {
    ${sharedToastStyle}
    background-color: ${({ theme }) => theme.colors.foreground};
    color: ${({ theme }) => theme.colors.primaryText};
    button[aria-label='close'] {
      color: ${({ theme }) => theme.colors.primaryText};
    }
  }
  .Toastify__alert-test-mode {
    ${sharedToastStyle}
    background-color: ${({ theme }) => theme.colors.foreground};
    color: ${({ theme }) => theme.colors.primaryText};
    button[aria-label='close'] {
      color: ${({ theme }) => theme.colors.primaryText};
    }
  }
`;
