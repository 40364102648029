import { useSelector } from 'react-redux';
import { LIGHT_THEME } from '@rsos/theme/constants';

const useSelectedTheme = () => {
  const applicationName = useSelector(
    state => state.sinatra.user.currentRole?.application,
  );

  const themeName = useSelector(
    state =>
      state.sinatra.user.profile?.attributes?.[applicationName]?.theme_settings
        ?.theme || LIGHT_THEME,
  );

  return themeName;
};

export default useSelectedTheme;
