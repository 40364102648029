import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { ThemeControlIcon } from '@rsos/assets/icons';
import { Box, Button } from '@rsos/base-components';
import useSelectedTheme from '@rsos/hooks/useSelectedTheme';
import useToggleBasemap from '@rsos/hooks/useToggleBasemap';
import { updateUserAttributes } from '@rsos/sinatra';
import { DARK_THEME, LIGHT_THEME } from '@rsos/theme/constants';
import useIsIRP from '@rsos/utils/useIsIRP';
import { trackThemeControlClicks } from './themeControlTrackings';

export const IconWrapper = styled(Box).attrs({
  color: 'secondaryText',
})``;

const ThemeControl = ({ path }) => {
  const dispatch = useDispatch();

  const { irpVersion } = useIsIRP();

  const applicationName = useSelector(
    state => state.sinatra.user.currentRole?.application,
  );

  const themeName = useSelectedTheme();

  const [shouldToggleBasemap, setShouldToggleBasemap] = useState(false);

  const handleThemeSwitch = useCallback(() => {
    const themeToSwitchTo =
      themeName === LIGHT_THEME ? DARK_THEME : LIGHT_THEME;

    const themeSettings = {
      theme_settings: {
        theme: themeToSwitchTo,
      },
    };

    trackThemeControlClicks(themeToSwitchTo, irpVersion);

    dispatch(updateUserAttributes(applicationName, themeSettings));

    setShouldToggleBasemap(true);
  }, [applicationName, dispatch, irpVersion, themeName]);

  useToggleBasemap(shouldToggleBasemap);

  const isThemeablePage =
    path === '/home' ||
    path === '/irp-home' ||
    path === '/query-interface' ||
    path === '/irp-query-interface' ||
    path === '/central-station' ||
    path.includes('/admin');

  return (
    <Box data-name="navigation-theme">
      {isThemeablePage && (
        <Button
          btnType="tertiary"
          size="small"
          onClick={handleThemeSwitch}
          data-name={`theme-control-turn-${
            themeName === LIGHT_THEME ? 'on' : 'off'
          }-night-mode`}
        >
          <IconWrapper>
            <ThemeControlIcon
              viewboxWidth={24}
              viewboxHeight={24}
              width={20}
              height={16}
            />
          </IconWrapper>
        </Button>
      )}
    </Box>
  );
};

export default withTheme(ThemeControl);
